import gql from "graphql-tag";
import moment from "moment";

export const GET_IMAGE_URL = gql`
  query MyQuery($id: Int!) {
    grapy_final(where: {id: {_eq: $id}}) {
        isLabelled
        id
        hat_seg
        hair_seg
        gloves_seg
        sunglasses_seg 
        socks_seg
        torso_skin_seg
        scarf_seg
        face_seg
        left_arm_seg
        right_arm_seg
        right_leg_seg
        left_shoe_seg
        right_shoe_seg
        rest_seg
        left_leg_seg
        is_perfect 
        isLabelled
        hat_assigned
        hair_assigned
        gloves_assigned
        sunglasses_assigned
        socks_assigned
        torso_skin_assigned
        scarf_assigned
        face_assigned
        left_arm_assigned
        right_arm_assigned
        right_leg_assigned
        left_leg_assigned
        left_shoe_assigned
        right_shoe_assigned
        person_id
        upper_clothes_seg
        lower_clothes_seg
        dress_seg
        belt_seg
        coat_seg
        heavy_tops_seg
        necklace_seg
        skirt_seg
        is_na
        reason_for_na
        coat_assigned
        bag_seg
        tie_seg
        other_accessory_seg
        upper_clothes_assigned
        lower_clothes_assigned
        skirt_assigned
        dress_assigned
        heavy_tops_assigned
        worst_case_labelled
    }
  }
`;

export const UPDATE_PERSON = gql`
  mutation MyMutation(
    $id: Int!,
    $hat_seg: String,
    $hair_seg: String,
    $gloves_seg: String,
    $sunglasses_seg: String, 
    $socks_seg: String,
    $torso_skin_seg: String,
    $scarf_seg: String,
    $face_seg: String,
    $left_arm_seg: String,
    $right_arm_seg: String,
    $right_leg_seg: String,
    $left_leg_seg: String,
    $left_shoe_seg: String,
    $right_shoe_seg: String,
    $rest_seg: String,
    $name: String,
    $seconds_spent: Int,
    $upper_clothes_seg: String,
    $lower_clothes_seg: String,
    $dress_seg: String,
    $belt_seg: String,
    $heavy_tops_seg: String,
    $skirt_seg: String,
    $coat_seg: String,
    $necklace_seg: String,
    $coat_assigned: String
    $other_accessory_seg: String,
    $bag_seg: String,
    $tie_seg: String,
    $last_updated: timestamptz,
    $worst_case_labelled: String,
    $prediction_type: String,
    $upper_clothes_assigned: String,
    $lower_clothes_assigned: String,
    $skirt_assigned: String,
    $dress_assigned: String,
    $heavy_tops_assigned: String,
  ) {
    update_grapy_final(
      where: { id: { _eq: $id } }
      _set: { 
          hat_seg:$hat_seg,
          hair_seg:$hair_seg,
          gloves_seg:$gloves_seg,
          sunglasses_seg:$sunglasses_seg, 
          socks_seg:$socks_seg,
          torso_skin_seg:$torso_skin_seg,
          scarf_seg:$scarf_seg,
          face_seg:$face_seg,
          left_arm_seg:$left_arm_seg,
          right_arm_seg:$right_arm_seg,
          right_leg_seg:$right_leg_seg,
          left_leg_seg: $left_leg_seg,
          left_shoe_seg:$left_shoe_seg,
          right_shoe_seg:$right_shoe_seg,
          rest_seg:$rest_seg,
          isLabelled: "Labelled", 
          name: $name,
          seconds_spent: $seconds_spent,
          upper_clothes_seg :$upper_clothes_seg,
          lower_clothes_seg :$lower_clothes_seg,
          dress_seg :$dress_seg,
          belt_seg :$belt_seg,
          heavy_tops_seg :$heavy_tops_seg,
          skirt_seg :$skirt_seg,
          coat_seg: $coat_seg,
          is_na: "No",
          reason_for_na: "No",
          necklace_seg: $necklace_seg,
          coat_assigned: $coat_assigned,
          tie_seg: $tie_seg,
          bag_seg: $bag_seg,
          other_accessory_seg:$other_accessory_seg,
          last_updated: $last_updated,
          worst_case_labelled:$worst_case_labelled,
          upper_clothes_assigned:$upper_clothes_assigned,
          lower_clothes_assigned:$lower_clothes_assigned,
          heavy_tops_assigned:$heavy_tops_assigned,
          dress_assigned:$dress_assigned,
          skirt_assigned:$skirt_assigned,
        }
    ) {
      affected_rows
    }
  }
`;

export const allParts = [
{
    variable: "hair_seg",
    color: "rgba(255, 0, 0, 255)",
    name: "Hair"
},
{
  variable: "torso_skin_seg",
  color: "rgba(211, 46, 208, 255)",
  name: "Torso skin"
},
{
  variable: "face_seg",
  color: "rgba(0, 0, 255, 255)",
  name: "Face"
},
{
  variable: "left_arm_seg",
  color: "rgba(158, 0, 89, 255)",
  name: "Left Arm Skin",
  classes: {
    width: '160px'
  },
  main_class: {
    borderRight: '4px solid black',
    marginRight: '5px'
  }
},
{
  variable: "right_arm_seg",
  color: "rgba(0, 255, 255, 255)",
  name: "Right Arm Skin",
  classes: {
    width: '160px'
  }
},
{
  variable: "left_leg_seg",
  color: "rgba(170, 255, 85, 255)",
  name: "Left Leg Skin",
  classes: {
    width: '160px'
  },
  main_class: {
    borderRight: '4px solid black',
    marginRight: '5px'
  }
},{
  variable: "right_leg_seg",
  color: "rgba(154, 3, 30, 255)",
  name: "Right Leg Skin",
  classes: {
    width: '160px'
  }
},
{
  variable: "left_shoe_seg",
  color: "rgba(0, 85, 0, 255)",
  name: "Left Shoe",
  classes: {
    width: '160px'
  },
  main_class: {
    borderRight: '4px solid black',
    marginRight: '5px'
  }
},
{
  variable: "right_shoe_seg",
  color: "rgba(255, 170, 0, 255)",
  name: "Right Shoe",
  classes: {
    width: '160px'
  }
},
{
  variable: "rest_seg",
  color: "rgba(85, 85, 85, 255)",
  name: "Rest"
},
{
  variable : "hat_seg",
  color: "rgba(67, 177, 255, 255)",
  name: "Hat"
},
{
  variable : "necklace_seg",
  color: "rgba(189, 113, 72, 255)",
  name: "Necklace / Chains"
},
{
  variable: "tie_seg",
  color: "rgba(253, 169, 127, 255)",
  name: "Tie",
},
{
  variable: "bag_seg",
  color: "rgba(226, 230, 45, 255)",
  name: "Bag",
},
{
  variable: "other_accessory_seg",
  color: "rgba(216, 167, 83, 255)",
  name: "Other Accessory",
},
{
  variable: "gloves_seg",
  color: "rgba(255, 255, 0, 255)",
  name: "Gloves"
},
{
  variable: "sunglasses_seg",
  color: "rgba(123, 56, 67, 255)",
  name: " Sunglasses"
},
{
  variable: "scarf_seg",
  color: "rgba(52, 86, 128, 255)",
  name: "Scarf"
},
{
  variable: "upper_clothes_seg",
  color: "rgba(32, 173, 10, 255)",
  name: "T shirt / Shirt"
},
{
  variable: "lower_clothes_seg",
  color: "rgba(255, 85, 0, 255)",
  name: "Pants / Shorts"
},
{
  variable: "dress_seg",
  color: "rgba(136, 10, 117, 255)",
  name: "Dress"
},
{
  variable: "belt_seg",
  color: "rgba(232, 125, 142, 255)",
  name: "Belt"
},
{
  variable: "skirt_seg",
  color: "rgba(162, 0, 220, 255)",
  name: "Skirt"
},
{
  variable: "socks_seg",
  color: "rgba(85, 85, 0, 255)",
  name: "Socks"
},
{
  variable: "heavy_tops_seg",
  color: "rgba(57, 45, 40, 255)",
  name: "Jackets/ Sweatshirts/ Hoodies/ Sweaters (Ends above the hips / shorter than coat)",
  classes: {
    color: 'white',
    width: '350px',
    height: '80px',
    whiteSpace: 'inherit',
  },
  // desc: "Ends above the hips / shorter than coat",
  main_class: {
    // border: '2px solid black',
    // marginBottom: '5px'
  }
},

{
  variable: "coat_seg",
  color: "rgba(0, 119, 221, 255)",
  name: "Coat",
  // desc: "Ends below the hips / Longer than jackets / hoodies / sweatshirts",
  classes: {
    color: 'white',
    width: '350px',
    height: '80px',
    whiteSpace: 'inherit',
  },
  extra_info: "Ends below the hips / (Longer than jackets / hoodies / sweatshirts)",
  extra_classes: {
    breakWord: 'none'
  },
  main_class: {
    // border: '2px solid black'
  }
},

]

export const allPartsForAssign = [
  {
    variable: "upper_clothes_seg",
    color: "rgba(32, 173, 10, 255)",
    name: "T shirt / Shirt"
  },
  {
    variable: "lower_clothes_seg",
    color: "rgba(255, 85, 0, 255)",
    name: "Pants / Shorts"
  },
  {
    variable: "dress_seg",
    color: "rgba(136, 10, 117, 255)",
    name: "Dress"
  },
  {
    variable: "skirt_seg",
    color: "rgba(162, 0, 220, 255)",
    name: "Skirt"
  },
  {
    variable: "heavy_tops_seg",
    color: "rgba(57, 45, 40, 255)",
    name: "Jackets/ Sweatshirts/ Hoodies/ Sweaters (Ends above the hips / shorter than coat)",
    classes: {
      color: 'white',
      width: '350px',
      height: '80px',
      whiteSpace: 'inherit',
    },
    // desc: "Ends above the hips / shorter than coat",
    main_class: {
      // border: '2px solid black',
      // marginBottom: '5px'
    }
  },
  {
    variable: "coat_seg",
    color: "rgba(0, 119, 221, 255)",
    name: "Coat",
    // desc: "Ends below the hips / Longer than jackets / hoodies / sweatshirts",
    classes: {
      color: 'white',
      width: '350px',
      height: '80px',
      whiteSpace: 'inherit',
    },
    extra_info: "Ends below the hips / (Longer than jackets / hoodies / sweatshirts)",
    extra_classes: {
      breakWord: 'none'
    },
    main_class: {
      // border: '2px solid black'
    }
  },
]

export const selectOptionsLabels = [
  {
      variable : "hat_seg",
      color: "#ff5500",
      name: "Hat"
  },
  {
      variable: "hair_seg",
      color: "#ffaa00",
      name: "Hair"
  },
  {
      variable: "gloves_seg",
      color: "#aa0033",
      name: "Gloves"
  },
  {
      variable: "sunglasses_seg",
      color: "#cccccc",
      name: " Sunglasses"
  },
  {
    variable: "socks_seg",
    color: "#cccccc",
    name: "Socks"
  },
  {
    variable: "torso_skin_seg",
    color: "#cccccc",
    name: "Torso skin"
  },
  {
    variable: "scarf_seg",
    color: "#cccccc",
    name: "Scarf"
  },
  {
    variable: "face_seg",
    color: "#cccccc",
    name: "Face"
  },
  {
    variable: "left_arm_seg",
    color: "#cccccc",
    name: "Left Arm"
  },
  {
    variable: "right_arm_seg",
    color: "#cccccc",
    name: "Right arm"
  },
  {
    variable: "right_leg_seg",
    color: "#cccccc",
    name: "Right leg"
  },
  {
    variable: "left_shoe_seg",
    color: "#cccccc",
    name: "Left shoe",
  },
  {
    variable: "right_shoe_seg",
    color: "#cccccc",
    name: "Right shoe"
  },
  {
    variable: "rest_seg",
    color: "#cccccc",
    name: "Rest"
  },
  
  ]

export const GET_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  grapy_final_aggregate(distinct_on: id, where: {isLabelled: {_eq: "Not Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
    }
  }
}
`;

export const UPDATE_PERSON_NA = gql`
  mutation MyMutation(
    $id: Int!,
    $is_na: String,
    $reason_for_na: String!,
    $isLabelled: String!,
    $last_updated: timestamptz,
    $worst_case_labelled: String,
    $upper_clothes_assigned: String,
    $lower_clothes_assigned: String,
    $dress_assigned: String,
    $skirt_assigned: String,
    $heavy_tops_assigned: String,
    $coat_assigned: String,
  ){
      update_grapy_final(
        where: { id: { _eq: $id } }
        _set: {
          isLabelled: $isLabelled,
          is_na: $is_na,
          reason_for_na: $reason_for_na
          last_updated: $last_updated
          worst_case_labelled: $worst_case_labelled
          upper_clothes_assigned:$upper_clothes_assigned,
          lower_clothes_assigned:$lower_clothes_assigned,
          heavy_tops_assigned:$heavy_tops_assigned,
          dress_assigned:$dress_assigned,
          skirt_assigned:$skirt_assigned,
          coat_assigned: $coat_assigned
        }
      ) {
        affected_rows
        returning {
          is_na
        }
      }
    }
`;

// export const WORST_CASE_QUERY = gql`
// query  MyQuery($id: Int!) {
//   grapy_final(order_by: {id: asc}, where: {worst_case_labelled: {_eq: "Not Labelled"}, id: {_gte: $id}}, limit: 2) {
//     id
//   }
// }
// `;

export const GET_WORSTCASE_UNLABELLED_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!, $worst_case_date: String!) {
  grapy_final_aggregate(distinct_on: id, where: {id: {_gte: $startId, _lte: $endId}, worst_case_date: {_eq: $worst_case_date}}) {
    aggregate {
      count
    }
    nodes {
      id
      is_na
      reason_for_na,
      worst_case_labelled,
      isLabelled,
    }
  }
}
`;

export const WORST_CASE_QUERY = gql`
query  MyQuery($id: Int!, $worst_case_date: String) {
  grapy_final(order_by: {id: asc}, where: {worst_case_date: {_eq: $worst_case_date}, id: {_gte: $id}}, limit: 2) {
    id
  }
}
`;


export const GET_NA_IDS = gql`
query MyQuery($startId: Int!, $endId: Int!) {
  grapy_final_aggregate(distinct_on: id, where: {is_na: {_eq: "Yes"}, isLabelled: {_eq: "Labelled"}, id: {_gte: $startId, _lte: $endId}}) {
    aggregate {
      count
    }
    nodes {
      id
      reason_for_na
    }
  }
}
`;

export const PASSWORD_QUERY = gql`
query MyQuery($password: String!) {
  password_for_tools_aggregate(where: {tool_name: {_eq: "GRAPY_21"}, password: {_eq: $password}}) {
    aggregate {
      count
    }
    nodes {
      locked_ids
    }
  }
}
`;

export const PRED_GOOD_AVAILABLE = true

export const TABLE = "grapy_final"

export const OUTPUT_BACKGROUND = 'https://storage.googleapis.com/naman-bucket/dataset/newpersons/'

export const OUTPUT_BACKGROUND_EXT = '.jpg'

export const PREDICTION = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_15_part/`

export const PREDICTION_EXT = `.png`

export const PREDICTION_FINAL = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_15_part_final/`

export const PREDICTION_REVIEW = `https://storage.cloud.google.com/labelling-tools-data/data-dumps/refined_grapy_15_part_final_review/`

// export const PREDICTION_EXT = `.png`