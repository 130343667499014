import { useEffect, useState } from "react";
import * as tf from "@tensorflow/tfjs";
import mergeImages from "merge-images";

export const loadImage = (url) => {
  const img = new Image()
  return new Promise(resolve => {
    img.onload = () => {
      const tempCanvas = document.createElement("canvas");
      let ctx = tempCanvas.getContext("2d");
      tempCanvas.style.width = 600 + "px";
      tempCanvas.style.height = 800 + "px";
      var scale = 1;
      tempCanvas.width = Math.floor(600 * scale);
      tempCanvas.height = Math.floor(800 * scale);
      ctx.scale(scale, scale);
      ctx.drawImage(
        img,
        0,
        0,
        img.naturalWidth,
        img.naturalHeight,
        0,
        0,
        600,
        800
      );
      const imageData = ctx.getImageData(0, 0, 600, 800);
      resolve(imageData)
    }
    img.src = url
    img.crossOrigin = "anonymous"
  })
}

export function create_full(predForAll) {
  console.log("full is created again")
  const tempCanvas = document.createElement("canvas");
  let ctx = tempCanvas.getContext("2d");
  tempCanvas.style.width = 600 + "px";
  tempCanvas.style.height = 800 + "px";
  var scale = 1;
  tempCanvas.width = Math.floor(600 * scale);
  tempCanvas.height = Math.floor(800 * scale);
  ctx.scale(scale, scale);
  const imageData = ctx.getImageData(0, 0, 600, 800);
  console.log("starts exec")

  Object.keys(predForAll).forEach((key) => {
    let c = 0
    if (key.indexOf("collar") == -1) {
      const mySet1 = new Set()
      for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = imageData.data[i] + predForAll[key].data[i]
        imageData.data[i] %= 256
        imageData.data[i + 1] = imageData.data[i + 1] + predForAll[key].data[i + 1]
        imageData.data[i + 1] %= 256
        imageData.data[i + 2] = imageData.data[i + 2] + predForAll[key].data[i + 2]
        imageData.data[i + 2] %= 256
        imageData.data[i + 3] = 255
      }
      ctx.putImageData(imageData, 0, 0);
    }
  })
  ctx.putImageData(imageData, 0, 0);
  return imageData
  // console.log(tempCanvas.toDataURL())
  // console.log("finishes")
}

export function create_overlap_region(full, allParts) {
  if (full != null) {
    let all_colors = new Set()
    allParts.forEach(part => {
      all_colors.add(part["color"])
    })
    const tempCanvas = document.createElement("canvas");
    let ctx = tempCanvas.getContext("2d");
    tempCanvas.style.width = 600 + "px";
    tempCanvas.style.height = 800 + "px";
    var scale = 1;
    tempCanvas.width = Math.floor(600 * scale);
    tempCanvas.height = Math.floor(800 * scale);
    ctx.scale(scale, scale);
    let imageData = ctx.getImageData(0, 0, 600, 800)
    ctx.putImageData(full, 0, 0)
    all_colors = Array.from(all_colors);
    let colors = []
    all_colors.map(color => {
      let rgb = color.substring(5, color.indexOf(")")).split(",").map((c, i) => c.trim())
      colors.push(rgb)
    })
    let tempfull = full
    for (let i = 0; i < full.data.length; i += 4) {
      let flag = true
      for (let j = 0; j < colors.length; j += 1) {
        if (
          // full.data[i + 0] < parseInt(colors[j][0]) + 30 && 
          // full.data[i + 0] > parseInt(colors[j][0]) -30 && 
          // full.data[i + 1] < parseInt(colors[j][1]) + 30 && 
          // full.data[i + 1] > parseInt(colors[j][1]) -30 && 
          // full.data[i + 2] < parseInt(colors[j][2]) + 30 && 
          // full.data[i + 2] > parseInt(colors[j][2]) -30 

          full.data[i + 0] == parseInt(colors[j][0]) &&
          full.data[i + 1] == parseInt(colors[j][1]) &&
          full.data[i + 2] == parseInt(colors[j][2])
          // true
        ) {
          // console.log("enters")
          tempfull.data[i + 0] = 0
          tempfull.data[i + 1] = 0
          tempfull.data[i + 2] = 0
          tempfull.data[i + 3] = 0
          flag = false

        }

        // if(flag== false){
        //   tempfull[i + 0] = full.data[i + 0]
        //   tempfull[i + 1] = full.data[i + 1]
        //   tempfull[i + 2] = full.data[i + 2]
        //   tempfull[i + 3] = 255
        // }
      }


    }


    for (let i = 0; i < tempfull.data.length; i += 4) {
      if(tempfull.data[i]!=0 && tempfull.data[i+1]!=0 && tempfull.data[i+2]!=0 && tempfull.data[i+3]!=0){
        tempfull.data[i + 0] = 255
        tempfull.data[i + 1] = 0
        tempfull.data[i + 2] = 0
        tempfull.data[i + 3] = 255
      }
    }
    for (let i = 0; i < tempfull.data.length; i += 4) {
      imageData.data[i + 0] = tempfull.data[i + 0]
      imageData.data[i + 1] = tempfull.data[i + 1]
      imageData.data[i + 2] = tempfull.data[i + 2]
      imageData.data[i + 3] = 255
    }
    return imageData;
    // ctx.putImageData(imageData, 0, 0);
    // console.log(tempCanvas.toDataURL())
  }
}

export function showFull(full, fullRef, width=600, height=800, transparent = true) {
  if (fullRef && fullRef.current) {
    let tempCanvas = document.createElement("canvas")
    let ctx = tempCanvas.getContext("2d");
    tempCanvas.style.width = 600 + "px";
    tempCanvas.style.height = 800 + "px";
    var scale = 1;
    tempCanvas.width = Math.floor(600 * scale);
    tempCanvas.height = Math.floor(800 * scale);
    ctx.scale(scale, scale);
    if(transparent){
      make_rest_transparent(full.data)
    }
    ctx.putImageData(full, 0, 0)
    let imageToPut = tempCanvas
    const finalCtx = fullRef.current.getContext("2d");
    if(width!=600 && height!=800){
      finalCtx.drawImage(imageToPut, 0, 0, 600, 800, 0, 0, width, height)
    }else{
      finalCtx.putImageData(full, 0, 0)
    }
  }
}

export default function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    }
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
    // Add event listeners
    useEffect(() => {
      window.addEventListener("keydown", downHandler);
      window.addEventListener("keyup", upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener("keydown", downHandler);
        window.removeEventListener("keyup", upHandler);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
    return keyPressed;
  }

export const make_assign_variable = (key) => {
  const variableName = key.split("_seg")[0];
  return `${variableName}_assigned`
}

export function resize(img, size, mask = false) {
  let frame_h = size[0];
  let frame_w = size[1];
  let image_h = img.shape.slice([1], [3])[0];
  let image_w = img.shape.slice([1], [3])[1];
  let h_r = frame_h / image_h;
  let w_r = frame_w / image_w;
  let r = Math.min(h_r, w_r);
  let dim = [Math.round(image_h * r), Math.round(image_w * r)];
  let resized;
  if (mask) {
    resized = tf.image.resizeNearestNeighbor(img, [800, 600], false, true);
  } else {
    resized = tf.image.resizeBilinear(img, [dim[0], dim[1]]);
  }
  let z = tf.zeros([800, 600, 1]);
  resized = tf.concat([resized, z], 2);
  resized = tf.util.flatten(resized.arraySync());
  return resized;
}

export function show_single_part(data, colors, removeRest = true) {
  for (let i = 0; i < data.length; i += 4) {
    if (
      data[i] <= parseInt(colors[0]) + 30 &&
      data[i] > parseInt(colors[0]) - 30 &&
      data[i + 1] <= parseInt(colors[1]) + 30 &&
      data[i + 1] > parseInt(colors[1]) - 30 &&
      data[i + 2] <= parseInt(colors[2]) + 30 &&
      data[i + 2] > parseInt(colors[2]) - 30
    ) {
      data[i + 3] = 255;
    } else {
      data[i] = 0;
      data[i + 1] = 0;
      data[i + 2] = 0;
      data[i + 3] = 0;
    }
  }
}

export function make_rest_transparent(data, transparent = 0, color = 255, removeRest = true) {
  if (color === 256) {
    for (let i = 0; i < data.length; i += 4) {
      data[i + 3] = 0;
    }
  } else {
    for (let i = 0; i < data.length; i += 4) {
      if (
        data[i] < 30 &&
        data[i + 1] < 30 &&
        data[i + 2] < 20
      ) {
        data[i + 3] = transparent;
      }
      if (removeRest) {
        if (
          // data[i] < 105 &&
          // data[i] > 50 &&
          // data[i + 1] < 105 &&
          // data[i + 1] > 50 &&
          // data[i + 2] < 105 &&
          // data[i + 2] > 50
          data[i] === data[i+1] &&
          data[i+1] === data[i+2] &&
          data[i+2] === data[i+3]
        ) {
          // data[i] = 0;
          // data[i + 1] = 0;
          // data[i + 2] = 0;
          data[i + 3] = transparent;
        }
      }
    }
  }
}

export const makePredictionData = function (image, setLivePrediction, SIZEX, SIZEY) {
  if (image !== "") {
    let newDummyImg = new Image();
    newDummyImg.src = image;
    newDummyImg.crossOrigin = "anonymous";
    newDummyImg.onload = () => {
      URL.revokeObjectURL(newDummyImg.src);
      let canvas = document.createElement("canvas");
      const instaCanvas = canvas;
      let ctx = instaCanvas.getContext("2d");
      canvas.style.width = SIZEX + "px";
      canvas.style.height = SIZEY + "px";
      var scale = 1;
      canvas.width = Math.floor(SIZEX * scale);
      canvas.height = Math.floor(SIZEY * scale);
      ctx.scale(scale, scale);
      ctx.drawImage(
        newDummyImg,
        0,
        0,
        newDummyImg.naturalWidth,
        newDummyImg.naturalHeight,
        0,
        0,
        SIZEX,
        SIZEY
      );

      const imageData = ctx.getImageData(0, 0, 600, 800);
      make_rest_transparent(imageData.data);
      ctx.putImageData(imageData, 0, 0);
      const picURL = canvas.toDataURL();
      setLivePrediction(picURL)
    }
  }
}

export const createAllSegments = (activeIndex, points, ctx, pointsColor, OFFSET_X, OFFSET_Y) => {
  if (activeIndex >= 0) {
    for (let j = points.length - 2; j < points.length - 1; j++) {
      ctx.beginPath();
      if (points[j].length > 0) {
        ctx.moveTo(points[j][0].x, points[j][0].y);
      }
      for (let i = 1; i < points[j].length; i++) {
        ctx.lineTo(points[j][i].x - OFFSET_X, points[j][i].y - OFFSET_Y);
      }
      ctx.lineWidth = 2;
      ctx.fillStyle = pointsColor[j].colorPreview;
      ctx.strokeStyle = pointsColor[j].colorPreview;
      ctx.fill();
      ctx.stroke();
      ctx.save();
    }
  }
  ctx.restore();
}

function make_base(ctx, src, sizeX, sizeY) {
  let base_image = new Image();
  base_image.src = src;
  base_image.onload = function () {
    URL.revokeObjectURL(base_image.src);
    ctx.drawImage(base_image, 0, 0, sizeX, sizeY);
  };
}

export function createImage(ctx, list, sizeX, sizeY) {
  let newlist = [];
  for (let i = 0; i < list.length; i += 1) {
    if (list[i] !== null && list[i] !== undefined) {
      newlist.push({
        src: list[i],
        opacity: 0.5,
      });
    }
  }
  mergeImages(newlist).then(async (b64) => {
    await make_base(ctx, b64, sizeX, sizeY);
  });
}


export  function getMousePos(cont, evt, lastPoint) {
  const rect = cont.current.getBoundingClientRect();
  let currentX =  evt.clientX - rect.x;
  let currentY = evt.clientY - rect.y;
  
  if(evt.target.id===""){
    currentX = currentX - 21
    currentY = currentY - 21
    if (currentX < 0 || currentX > 600 || currentY < 0 || currentY > 800) {
      if(lastPoint.x == 600 || lastPoint.x == 0){
        return {x: lastPoint.x, y: currentY > 800 ? 800 : currentY < 0 ? 0: currentY };
      }
      if(lastPoint.y == 800 || lastPoint.y == 0){
        return {x: currentX > 600 ? 600 : currentX < 0 ? 0: currentX, y: lastPoint.y}
      }
    let intersectionPoints = []
    // console.log(currentX, currentY, lastPoint)
    // console.log(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800))
    // console.log()
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 600, 0)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 600, 0));
    }
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 800, 600, 800)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 800, 600, 800));
    }
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 600, 0, 600, 800));
    }
    if(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 0, 800)){
      intersectionPoints.push(intersect(currentX, currentY, lastPoint.x, lastPoint.y, 0, 0, 0, 800));
    }
  
    for(let i = 0; i < intersectionPoints.length; i++){
      let intersectionPointX = intersectionPoints[0].x
      let intersectionPointY = intersectionPoints[0].y
      let minX = Math.min(currentX, lastPoint.x)
      let minY = Math.min(currentY, lastPoint.y)
      let maxX = Math.max(currentX, lastPoint.x)
      let maxY = Math.max(currentY, lastPoint.y)
      if(intersectionPointX >= minX && intersectionPointX <= maxX && intersectionPointY >= minY && intersectionPointY <= maxY){
        return {
          x: intersectionPointX,
          y: intersectionPointY
        }
      }
  
    }
    }
  }
    return {
      x: evt.clientX - rect.x,
      y: evt.clientY - rect.y,
    };
}

export function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {

  // Check if none of the lines are of length 0
	if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
		return false
	}

	let denominator = ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1))

  // Lines are parallel
	if (denominator === 0) {
		return false
	}

	let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator
	let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator

  // is the intersection along the segments
	if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
		return false
	}

  // Return a object with the x and y coordinates of the intersection
	let x = x1 + ua * (x2 - x1)
	let y = y1 + ua * (y2 - y1)

	return {x: Math.round(x), y: Math.round(y)}
}

export function arrangeAllParts(allParts){
  const assign_ids = {
    
    "hat_seg": 1,
    "hair_seg": 2,
    "gloves_seg": 3,
    "sunglasses_seg": 4,
    "upper_clothes_seg": 5,
    "dress_seg": 6,
    "coat_seg": 7,
    "socks_seg": 8,
    "lower_clothes_seg": 9,
    "torso_skin_seg": 10,
    "scarf_seg": 11,
    "skirt_seg": 12,
    "face_seg": 13,

    "right_arm_seg": 14,
    "left_arm_seg": 15,

    "right_leg_seg": 16,
    "left_leg_seg": 17,

    "right_shoe_seg": 18,
    "left_shoe_seg": 19,

    "belt_seg": 20,
    "heavy_tops_seg": 21,
    "necklace_seg": 22,
    "other_accessory_seg": 23,
    "tie_seg": 24,
    "bag_seg": 25,  
    "rest_seg": 26,
  }

  // let arrangedAllParts = [...allParts].sort(function(a,b){ return assign_ids[a["variable"] - assign_ids[b["variable"]]] }).reverse()
  // console.log(arrangedAllParts)
  let allPartsObject = transformArrayToObject(allParts, "variable")
  // console.log(allPartsObject,  "in func")
  let arrangedAllParts = []
  Object.keys(assign_ids).forEach((key)=>{
    if(allPartsObject[key]){
      arrangedAllParts.push(allPartsObject[key])
    }
  })
  // console.log(arrangedAllParts, "arranged all parts")
  return arrangedAllParts
}

export function arrangeAllPartsPoints(newPointsColorsArray){
  const assign_ids = {
    
    "hat_seg": 1,
    "hair_seg": 2,
    "gloves_seg": 3,
    "sunglasses_seg": 4,
    "upper_clothes_seg": 5,
    "dress_seg": 6,
    "coat_seg": 7,
    "socks_seg": 8,
    "lower_clothes_seg": 9,
    "torso_skin_seg": 10,
    "scarf_seg": 11,
    "skirt_seg": 12,
    "face_seg": 13,

    "right_arm_seg": 14,
    "left_arm_seg": 15,

    "right_leg_seg": 16,
    "left_leg_seg": 17,

    "right_shoe_seg": 18,
    "left_shoe_seg": 19,

    "belt_seg": 20,
    "heavy_tops_seg": 21,
    "necklace_seg": 22,
    "other_accessory_seg": 23,
    "tie_seg": 24,
    "bag_seg": 25,  
    "rest_seg": 26,
  }
  let finalPoints = []
  let finalPointsColor = []
  Object.keys(assign_ids).forEach((key)=>{
    newPointsColorsArray.map(point => {
      if(point["variable"]==key){
        finalPoints.push(point)
        // finalPointsColor.push(point["pointsColor"])
      }
    })
  })

  return finalPoints
}

export function transformArrayToObject(arrayToTransform, key){
  let objectNew = {}
  arrayToTransform.map(ele => {
    objectNew[ele[key]] = ele
  })
  return objectNew
}

export function arrangePoints(points, pointsColor){
  let newPointsColorsArray = []
  for (let i = 0; i < points.length - 1; i++) {
    // console.log(pointsColor)
    if(pointsColor[i]["activeVariable"]){
      newPointsColorsArray.push({ 
        "points": points[i],
        "variable": pointsColor[i]["activeVariable"],
        "pointsColor": pointsColor[i]
      })
    }
  }

  let arrangedNewPointsColorsArray = arrangeAllPartsPoints(newPointsColorsArray)
  // console.log(arrangedNewPointsColorsArray,  "arrangedNewPointsColorsArray")
  let finalPoints = []
  let finalPointsColor = []
  for (let i = 0; i < arrangedNewPointsColorsArray.length; i++){
    finalPoints.push(arrangedNewPointsColorsArray[i]["points"])
    finalPointsColor.push(arrangedNewPointsColorsArray[i]["pointsColor"])
  }
  return [finalPoints, finalPointsColor];
}