import React, { useState, useEffect } from "react";
import Home from "./home";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import gql from "graphql-tag";
import {GET_IMAGE_URL, TABLE, WORST_CASE_QUERY, PASSWORD_QUERY} from "../main";
import moment from "moment";

export const UPDATE_TIME = gql`
  mutation MyMutation(
    $id: Int!,
    $time: String!
  ) {
    update_grapy_final(
      where: { id: { _eq: $id } }
      _set: {
        last_visited: $time
        }
    ) {
      affected_rows
    }
  }
`;

function App(props) {

  const history = useHistory();

  const { data: data, loading: loading } = useQuery(GET_IMAGE_URL, {
    variables: {
      id: parseInt(props.match.params.imageid)
    }
  });

  const getWorstCaseDate = (query) => {
    if(query){
      query = query.split("?")
      if(query.length > 0){
        query = query[1];
        query = query.split("=")
        if(query.length > 0){
          return query[1];
        }
      }
    }
  }

  const getPassword = (name) => {
    if(name){
    let password = name.split("-")[name.split("-").length - 1]
    if((password.split("a")[0].length === password.length) && (password.split("b")[0].length === password.length) && (password.split("c")[0].length === password.length) ){
      return ""
    }
    if(password){
      if(password.split("a")[0].length !== password.length){
        return password.split("a")[0]
      }
      if(password.split("b")[0].length !== password.length){
        return password.split("b")[0]
      }
      if(password.split("c")[0].length !== password.length){
        return password.split("c")[0]
      }
    }
    else{
      return ""
    }
    }else{
      return ""
    }
  }

  const { data: worstCaseData, loading: worstCaseLoading } = useQuery(WORST_CASE_QUERY,{
    variables: {
      id: parseInt(props.match.params.imageid),
      worst_case_date: getWorstCaseDate(props.location.search)
    }
  });

  const { data: passwordData, loading: passwordDataLoading } = useQuery(PASSWORD_QUERY, {
    variables: {
      password: getPassword(props.match.params.name)
    }, 
  });

  useEffect(() => {
    if(worstCaseData && worstCaseData[TABLE].length > 0 && props && (props.location.search.includes("minorRefinement") | (props.location.search.includes("worst_case")))){
      let flag = false;
      for(let i = 0;i<worstCaseData[TABLE].length;i++) {
        if(worstCaseData[TABLE][i].id==props.match.params.imageid){
          flag = true;
        }
      }
      if(!flag){
        history.push(`/name=${props.match.params.name}/imageid=${worstCaseData[TABLE][0].id}${props.location.search}`)
      }
    }
  }, [worstCaseData])

  const [update_time] = useMutation(UPDATE_TIME)

  const linkChange = (link) => {
    if (link === undefined) {
      return undefined;
    } else {
      return `https://storage.googleapis.com/download/storage/v1/b/${
        link.split("/")[3]
        }/o/${link
          .substring(34 + link.split("/")[3].length)
          .replace(/[/]/g, "%2F")}?alt=media`;
    }
  };
  const handleNext = async () => {
    let nextid = parseInt(props.match.params.imageid) + 1;
    history.push(`/name=${props.match.params.name}/imageid=${nextid}${props.location.search}`)
  };

  const check_for_locked_ids = () => {

    if(passwordData){
      if(passwordData[`password_for_tools_aggregate`] && passwordData[`password_for_tools_aggregate`]['aggregate']['count']!=0){
        if(passwordData[`password_for_tools_aggregate`]['nodes'][0]['locked_ids'].includes(parseInt(props.match.params.imageid))){
          return false;
        }else{
          return true
        }}
      }
    }

  if (loading && passwordDataLoading) {
    return <div>loading</div>;
  }
  if((props.location.search.includes("minorRefinement") | (props.location.search.includes("worst_case"))) && worstCaseData && worstCaseData[TABLE].length === 0){
    return <div>No worst case ahead left</div>
  }
  if(!passwordDataLoading && passwordData && passwordData[`password_for_tools_aggregate`]){
    if(passwordData[`password_for_tools_aggregate`]['aggregate']['count'] == 0){
      return <div>Password is wrong</div>
    }else{
      if(!check_for_locked_ids()){
        return <div>Id is locked</div>
      }
    }
  }

  if(data){
  return (
    <div>
      <Home person_data={data && data[TABLE][0]} nextImage={handleNext} props={props} />
    </div>
  );
  }
  return <div></div>

}

export default App;
